import React, {useEffect} from 'react'
import {Card, Avatar, Col} from 'antd'
import { CloseOutlined, EllipsisOutlined, DeleteOutlined, SettingOutlined } from '@ant-design/icons'
import RemoveIgAccount from '../Common/ApiCall/RemoveIgAccount'
import {BadNotif, SuccessNotif} from '../Common/Utils/SendNotification'

const {Meta} = Card

const IgAccountCard = ({ig_id, ig_user, ig_username, igAccounts, ig_profile_url, refresh}) => {

  function removeIgAccount(ig_id) {
    RemoveIgAccount(ig_id)
    .then(r=>{
      refresh()
      if(r.success) {
        SuccessNotif('IG Account Dissociated')
      } else {
        BadNotif(r)
      }
    })
  }

  /*
  const getIgId = (username) => {
    for(let i=0;i<igAccounts.length;i++) {
      if(igAccounts[i].username === username) {
        return igAccounts[i].ig_id
      }
    }
  }

  useEffect(() => {
    if((ig_user.biography && ig_user.biography === "COULD_NOT_ACCESS_THIS_USER")) {      
      removeIgAccount(getIgId(ig_username))
    }
  }, [])*/

    return(
      <Col xs={{span: 11}} md={{span: 8}} lg={{span: 7}}>
        <Card
          key={ig_id}
        >
        <Meta
          title={
            <div>
              @{ig_username}
              <CloseOutlined className="menu-float-right"  onClick={()=>removeIgAccount(ig_id)} />
            </div>
          }
        />
        </Card>
      </Col>
    )
}

export default IgAccountCard