import {GetRequest} from "./Requests";
import ApiResponse from "../Models/ApiResponse";
import ApiError from "../Models/ApiError"
import {RunServer, GenerateUrl} from "./ApiUrl";
import authHeader from './auth-header'

const GetOldSubscription = async () => {
  let apiResponse;
  if ( RunServer || process.env.NODE_ENV !== "development") {
    apiResponse = await GetRequest(GenerateUrl("/subscription_details"), {}, authHeader());
  } else {
    apiResponse = new ApiResponse(basePlan(), 200, null)
  }

  if (apiResponse.body) {
    return apiResponse.body;
  } else {
    return apiResponse.error;
  }
};

function basePlan(newParam = {
  "data":{"subscription_exists":true,"details":{"subscription_id":"sub_HtXMIhmjxkvT59","customer_id":"cus_HlmxPcxKf7LAkF","plan":"Basic","level_2_calls_allowed":300000,"level_3_calls_allowed":10000,"renew_date":"2020-11-24T00:54:50.000Z"}},"success":true,"error":null
}) {
  return newParam
}

export default GetOldSubscription
