import React, { useEffect, useState } from 'react'
import { Modal, Button, message, Form, Spin, Tag, Input } from 'antd';
import ReactFlagsSelect from "react-flags-select";
import CheckoutPaymentScreen from "./CheckoutPaymentScreen";
import { BadNotif, SuccessNotif } from '../Common/Utils/SendNotification';
import ValidateCoupon from '../Common/ApiCall/ValidateCoupon'
import CheckoutPaypalButton from './CheckoutPaypalScreen';
import PaypalCheckoutImage from "../Common/Images/checkout-paypal.png";
import StripeCheckoutImage from "../Common/Images/checkout-stripe.png";
import './payment.sass'
import * as Sentry from '@sentry/react'

const BoosterModal = ({visible, back, email, pId, price_in_cents}) => {
    const [loading, setLoading] = useState(false)
    const [cc, setCc] = useState('US')
    const [cuurency, setCurrency]=useState('usd')
    const [done, setDone] = useState(false)
    const [couponCode, setCouponCode] = useState('')
    const [paypal, setPaypal] = useState(false)
    const [validCoupon, setValidCoupon] = useState(false)
    const [disc, setDisc] = useState(0)
    const [load, setLoad] = useState(false)

    function next(paypal = false) {
        let currency = 'usd';
        if(cc === 'IN') {
          currency = 'inr'
        }
        setPaypal(paypal)
        setCurrency(currency)
        setDone(true)
    }

    function validateCoupon() {
      if (couponCode.length < 1) {
          message("Enter coupon code to continue");
      }
      setLoad(true)
      ValidateCoupon(couponCode).then((r) => {
        setLoad(false)
        if (r.success) {
          if(r.data.valid) {
            setValidCoupon(true);
            setDisc(r.data.percent_off)
            SuccessNotif(r.data.percent_off + "% Off Applied");
          } else {
            BadNotif(null, 'Invalid Coupon')
          }
        } else {
          Sentry.captureException(r)
          BadNotif(r, '');
        }
      });
    }

    function removeCoupon() {
      setValidCoupon(false);
      setDisc(0)
      setCouponCode("");
  }

    if(done) {
      if(paypal) {
        return (
          <CheckoutPaypalButton 
            email={email}
            price_id={pId}
            currency={"USD"}
            back={()=>{setDone(false);setPaypal(false)}}
            price_in_cents={price_in_cents}
            discount_percentage={disc}
          />
        )
      }
        return(
            <CheckoutPaymentScreen 
                email={email}
                price_id={pId}
                discount_code={validCoupon ? couponCode : ''}
                currency={cuurency}
                goBack={()=>setDone(false)}
            />
        )
    }

    return(
        <>
        <Modal
          visible={visible}
          title="Enter your Country"
          onOk={next}
          onCancel={back}
          footer={[
            <Button key="back" onClick={back}>
              Return
            </Button>
          ]}
        >
            <Form>
              <Form.Item>
                <ReactFlagsSelect
                        defaultCountry={cc}
                        searchable
                        searchPlaceholder="Search for a country"
                        onSelect={cc => setCc(cc)}
                />
              </Form.Item>
              {load && <Spin />}
              {validCoupon ? (
                <Form.Item>
                  <Tag color="success" closable onClose={removeCoupon}>
                    {couponCode}
                  </Tag>
                </Form.Item>
              ) : (
                <Form.Item label="Have a coupon? ">
                  <Input
                    style={{ width: 200 }}
                    value={couponCode}
                    onPressEnter={validateCoupon}
                    onChange={(e) =>
                      setCouponCode((e.target.value).toUpperCase())
                    }
                  />
                    {couponCode.length > 1 && (
                      <Button onClick={validateCoupon}>
                        Apply
                      </Button>
                    )}
                </Form.Item>
              )}
              <div className="has-text-centered" >
                <img 
                  className="payment-card"
                  src={StripeCheckoutImage}
                  alt="Pay with Stripe"
                  onClick={()=>next()}
                />
                {/*&nbsp;OR&nbsp;*/}
                {/*<img */}
                {/*  className="payment-card"*/}
                {/*  src={PaypalCheckoutImage}*/}
                {/*  alt="Pay with Paypal"*/}
                {/*  onClick={()=>next(true)}*/}
                {/*/>*/}
              </div>
            </Form>
        </Modal>
      </>
    )
}

export default BoosterModal