import React from "react";
import * as Sentry from "@sentry/react";
import ReactGA from 'react-ga'
import { Button } from "antd";

class ErrorBoundary extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      error: null
    }
  }

  static getDerivedStateFromError(error) {
    return {hasError: true}
  }

  componentDidCatch(error, errorInfo) {
    this.setState({error: error.toString()});
    console.log(error, errorInfo);
    Sentry.captureMessage(`Error in page ${this.props.page}`);
    Sentry.captureException(error);
    Sentry.captureException(errorInfo);
    ReactGA.event({
      category: 'Error',
      action: error,
      label: JSON.stringify(errorInfo)
    })
  }

  render() {
    if (this.state.hasError) {
      return(
        <div className="vertically-center">
          <h1>{`Something went wrong - Reason -> ${this.state.error}`}</h1>
          <br />
          Try Reloading, <Button onClick={()=>window.location.reload()}>Reload</Button>
        </div>
      );
    }

    return this.props.children;
  }

}

export default ErrorBoundary