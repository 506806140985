import React from "react";
import {Col, Row, Spin} from "antd";
import ApiError from "../Common/Models/ApiError";
import SendNotification from "../Common/Utils/SendNotification";
import NotificationTypeEnum from "../Common/Models/NotificationTypeEnum";
import MakeCheckoutSession from "../Common/ApiCall/MakeCheckoutSession";
import ContentWrapper from "../Components/ContentWrapper";
import Cookies from 'universal-cookie'
import * as Sentry from "@sentry/react";

const cookies = new Cookies()

class CheckoutPaymentScreen extends React.Component {

  getReferCookie() {
    let cookie = cookies.get("_fprom_track")
    if(cookie === undefined) {
      cookie = ''
    } 
    return cookie
  }

  async componentDidMount() {
    let stripe;
    if(process.env.NODE_ENV !== 'development') {
      stripe = window.Stripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY.toString());
    } else {
      stripe = window.Stripe(process.env.REACT_APP_STRIPE_TEST_KEY.toString())
    }
    const sessionId = await MakeCheckoutSession(
      this.props.email,
      this.props.price_id,
      this.props.currency,
      this.getReferCookie(),
      this.props.discount_code
    );
    if (sessionId instanceof ApiError) {
      Sentry.captureMessage(sessionId.message)
      SendNotification(NotificationTypeEnum.Failure, sessionId.message, true);
      this.props.goBack()
    }
    await stripe.redirectToCheckout({
      sessionId: sessionId
    });
  }

  render() {
    return (
      <ContentWrapper>
        <Row type="flex" justify="space-around" align="middle">
          <Col>Redirecting to Stripe ...<Spin size="large"/></Col>
        </Row>
      </ContentWrapper>
    )
  }
}

export default CheckoutPaymentScreen
