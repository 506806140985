import {PostRequest} from "./Requests";
import ApiError from "../Models/ApiError";
import {GenerateUrl} from "./ApiUrl";

const SuccessfulPayment = async (token_id) => {
  const apiResponse = await PostRequest(GenerateUrl("/paypal_success_payment", true), {
      token_id,
      secret: 'D13487E5945DFFCC2C925D3B274CF'
  });

  if (apiResponse.isValid()) {
    return apiResponse.body;
  } else {
    let message = `Something bad happened. Error: ${apiResponse.error.errors[0].message}. Please contact support@grambuddy.com (expect quick reply)`;
    return new ApiError(message);
  }
};

export default SuccessfulPayment;
