import { GetRequest, PostRequest} from "./Requests";
import ApiResponse from "../Models/ApiResponse"
import {GenerateUrl, RunServer} from "./ApiUrl";
import authHeader from './auth-header'

export const GetWhitelabelAccounts = async () => {
  let apiResponse;
  if (RunServer || process.env.NODE_ENV !== "development") {
    apiResponse = await GetRequest(
      GenerateUrl("/whitelabel_details", true), {}, authHeader())
  } else {
    apiResponse = new ApiResponse(dummyPlans(), 200, null)
  }
  if (apiResponse.body) {
    return apiResponse.body;
  } else {
    return apiResponse.error;
  }
};

export const AddWhitelabelAccount = async (ig_id, username, profilePic) => {
  let apiResponse;
  if (RunServer || process.env.NODE_ENV !== "development") {
    apiResponse = await PostRequest(
      GenerateUrl("/add_whitelabel_account", true), 
      {
          ig_id, username, profile_pic: profilePic
      },
      authHeader()
  )} else {
    apiResponse = new ApiResponse(dummyPlans(), 200, null)
  }
  if (apiResponse.body) {
    return apiResponse.body;
  } else {
    return apiResponse.error;
  }
};

export const RemoveWhitelabel = async (ig_id) => {
  let apiResponse;
  if (RunServer || process.env.NODE_ENV !== "development") {
    apiResponse = await PostRequest(
      GenerateUrl("/remove_whitelabel", true), { ig_id },
      authHeader())
  } else {
    apiResponse = new ApiResponse(dummyPlans(), 200, null)
  }
  if (apiResponse.body) {
    return apiResponse.body;
  } else {
    return apiResponse.error;
  }
};

function dummyPlans() {
  return {
    data: {
      "id": 1,
      "email": "admin@website.com",
    },
    success: true,
    errors: []
  }
}
