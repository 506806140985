import React, {useEffect, useState} from "react";
import ContentWrapper from "../Components/ContentWrapper";
import {Row, Col, Typography, Card, Button, List, Skeleton, Avatar, Spin} from 'antd'
import PlainContentWrapper from "../Components/PlainContentWrapper";
import {Input} from "antd";
import SendNotification, { BadNotif, SuccessNotif } from "../Common/Utils/SendNotification";
import NotificationTypeEnum from "../Common/Models/NotificationTypeEnum";
import GetIgDataFromUsername from "../Common/ApiCall/GetIgDataFromUsername";
import ApiError from "../Common/Models/ApiError";
import ReactGA from 'react-ga'
import * as Sentry from '@sentry/react'
import {CopyToClipboard} from "react-copy-to-clipboard/lib/Component";
import { RemoveWhitelabel, AddWhitelabelAccount , GetWhitelabelAccounts } from "../Common/ApiCall/WhitelabelApiCalls";

const {Search} = Input
const {Title} = Typography
const {Meta} = Card

const VIPExtensionUrl  = "https://chrome.google.com/webstore/detail/vip-member/mkkcdpilpcbaoidjcjlolilfainfgdbn?hl=en"

const VIPZipLink = "https://www.vipmemberprofiles.com/"

const ExtensionUrl = VIPZipLink


const WhiteLabel = ({setKey}) => {
  const [accounts, setAccounts] = useState(0)
  const [users, setUsers] = useState([])
  const [iniLoading, setInitialLoading] = useState(true)
  const [igUser, setIgUser] = useState(null)
  const [loading, setLoading] = useState(false)

  function getData() {
    GetWhitelabelAccounts()
      .then(r=>{
        setInitialLoading(false)
        if(r.success) {
          setAccounts(r.data.accounts_left)
          setUsers(r.data.accounts)
        } else {
          BadNotif(r)
        }
      })
  }

  useEffect(()=>{
    getData()
    setKey()
  }, [])

  async function makeDetailedIDFromUsernames (username) {
    setLoading(true);
    let userData = await GetIgDataFromUsername(username);
    if (userData instanceof ApiError) {
      if (userData.message === "User does not exist") {
        SendNotification(NotificationTypeEnum.Failure, "Instagram username does not exist");
      } else {
        SendNotification(NotificationTypeEnum.Failure, 'Something went Wrong')
      }
      setLoading(false)
      return
    }
    if(userData.biography === "COULD_NOT_ACCESS_THIS_USER") {
      SuccessNotif('Check Instagram Username and Try again.')
      return
    }
    ReactGA.event({
      category: 'Whitelabel',
      action: 'Add User to Whitelabel'
    })
    AddUser(userData)
  }

  function removeUser() {
    setIgUser(null)
  }

  function onChangeUsersId (e){
    if(accounts<1) {
      SendNotification(NotificationTypeEnum.Failure, "You have reached user's limit")
    } else {
      if(e.length<1) {
        SendNotification(NotificationTypeEnum.Failure, "Enter Valid Username")
      } else {
        makeDetailedIDFromUsernames(e)
      }
    }
  }

  function AddUser(userData) {
    AddWhitelabelAccount(userData.id, userData.username, userData.profilePic)
      .then(r => {
        if(r.success) {
          SendNotification(NotificationTypeEnum.Success, 'User Added')
        } else {
          Sentry.captureEvent(r)
          SendNotification(NotificationTypeEnum.Failure, 'Adding User Failed')
        }
        getData()
        removeUser()
        setLoading(false)
      })
  }

  function Revoke(user_id) {
    ReactGA.event({
      category: 'Whitelabel',
      action: 'Revoke Whitelabel Permission'
    })
    RemoveWhitelabel(user_id)
      .then(r=>{
        if(r.success) {
          SendNotification(NotificationTypeEnum.Success, "User Removed")
        } else {
          Sentry.captureEvent(r)
          SendNotification(NotificationTypeEnum.Failure, 'Removal Failed! Try again')
        }
        getData()
      })
  }

/*   if(iniLoading) {
    return <div
        style={{
          textAlign: 'center',
          marginTop: 12,
          height: 32,
          lineHeight: '32px',
        }}
      >
        <Button>loading</Button>
      </div>
  } */

  return(
    <PlainContentWrapper marginLess>
      <Row gutter={24}>
        <Col md={{span: 20}}>
          <Title>
          Reseller License
          </Title>
          <Search
            placeholder="ENTER INSTAGRAM USERNAME"
            enterButton={"Confirm"}
            size="large"
            loading={loading}
            disabled={loading}
            onSearch={(e)=>onChangeUsersId(e)}
            className="search-input"
          />
        </Col>
        <Col md={{span: 4}} className="align-center">
          <Card title="Accounts left" className="accounts-card">
            <Title>
              {accounts}
            </Title>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col>
          <Title level={4} style={{display: 'inline'}}>
            Download Options:
          </Title>
          &nbsp;&nbsp;&nbsp;
          <Button href={ExtensionUrl} target="_blank" type="primary dark-button" shape="round" size="large">
            DOWLOAD EXTENSION
          </Button>
          &nbsp;&nbsp;
          <CopyToClipboard text={ExtensionUrl} onCopy={()=>{SuccessNotif("URL Copied to Clipboard")}} >
            <Button type="primary dark-button" shape="round" size="large">
              COPY DOWLOAD LINK
            </Button>
          </CopyToClipboard>
        </Col>
      </Row>

      <Row gutter={24}>
        <Col span={24}>
          <Card title="Accounts" className="accounts-card">
            <List
              itemLayout="horizantal"
              dataSource={users}
              loading={iniLoading}
              renderItem={item => (
                <List.Item
                  actions={[<Button onClick={()=>Revoke(item.ig_id)} type="primary dark-button" key="list-loadmore-more">Revoke</Button>]}
                >
                  <Skeleton avatar loading={iniLoading} title={false} active>
                    <List.Item.Meta
                      avatar={
                        <Avatar src={item.profile_pic} />
                      }
                      title={`@${item.username}`}
                    />
                    <p>Created At: {(item.created_at).slice(0,10)}</p>
                  </Skeleton>
                </List.Item>
              )}
            />
          </Card>
        </Col>
      </Row>

    </PlainContentWrapper>
  )
}

export default WhiteLabel