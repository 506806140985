import React, {useEffect, useState} from 'react'
import {Typography, Modal, Divider, Row, Card, Col, Button, Form, Input} from 'antd'
import PlainContentWrapper from '../Components/PlainContentWrapper'
import SendNotification, {BadNotif, SuccessNotif} from "../Common/Utils/SendNotification";
import NotificationTypeEnum from "../Common/Models/NotificationTypeEnum";
import ChangePasswordCall from "../Common/ApiCall/ChangePasswordCall";
import Loader from "../Components/Loader";
import GetOldSubscription from '../Common/ApiCall/GetOldSubscription';
import CancelSubscription from '../Common/ApiCall/CancelSubscription'

const {Title} = Typography

const layout ={
    labelCol: {
        span: 8
    },
    wrapperCol: {
        span: 16
    }
}

const tailLayout ={
    wrapperCol: {
        offset: 8,
        span: 16
    }
}

const OldSubscription = ({email}) => {
    const [details, setDetails] = useState({})
    const [loading, setLoading] = useState(true)
    const [subs_exists, set_subs_exist] = useState(false)

    function getData() {
        GetOldSubscription()
        .then(r => {
            if(r.success) {
                set_subs_exist(r.data.subscription_exists)
                setDetails(r.data.details)
            }
            setLoading(false)
        })
    }

    useEffect(() => {
        getData()
    }, [])

    function cancelSubscription() {
        CancelSubscription(details.customer_id)
            .then(r => {
                if(r.success) {
                    SuccessNotif('Subscription Cancelled')
                    getData()
                }
            })
    }

    if(loading || !subs_exists) {
        return null
    }
    return(
        <Card>
            <Title level={4}>
            You have an active subscription
            </Title>
        <Form {...layout} >
            <Form.Item
                label="Plan Name"
            >
                {details.plan}
            </Form.Item>
            <Form.Item
                label="Renew Date:"
            >
                {String(details.renew_date).slice(0,10)}
            </Form.Item>
            <Form.Item {...tailLayout}>
                <Button type="danger" onClick={()=>cancelSubscription()}>
                    Cancel
                </Button>
            </Form.Item>
        </Form>
        </Card>
    )
}

const MyAccount = ({user, setKey}) => {
    const [passwordModal, setPasswordModal] = useState(false)
    const [loading, setLoading] = useState(false)
    const [form] = Form.useForm();
    const [, forceUpdate] = useState();

    useEffect(() => {
        forceUpdate({});
    }, []);

    const onFinish = values => {
        setLoading(true)
        if(values.password.length<8 || values.confirm_password.length<8) {
            SendNotification(NotificationTypeEnum.Failure, "Password should be minimum 8 characters")
            setLoading(false)
            return
        }
        if(values.password !== values.confirm_password) {
            SendNotification(NotificationTypeEnum.Failure, "Passwords don't match")
            setLoading(false)
            return
        }
        ChangePasswordCall(user.email, values.password)
          .then(r => {
              if(r.success) {
                  SuccessNotif("Password Changed Successfully")
              } else {
                  BadNotif(r, "Password Change Failed")
              }
              setLoading(false)
          })
    };

    useEffect(()=>setKey(), [])

    return(
        <PlainContentWrapper marginLess classAppend="is-padded-top" >
        <Title>
          My Account
        </Title>
        <Divider />
        <OldSubscription email={user.email} />
        <Modal
            visible={passwordModal}
            title="Change Password"
            footer={null}
            onCancel={()=>setPasswordModal(false)}
        >
            {loading ? <Loader />: null}
            <Form
              form={form}
              onFinish={onFinish}
                hideRequiredMark
            >
                <Form.Item
                  name="password"
                  rules={[{ required: true, message: 'Please Input Password'}]}
                >
                    <Input.Password placeholder="Enter New Password" />
                </Form.Item>
                <Form.Item
                  name="confirm_password"
                  rules={[{required: true, message: 'Enter Password Again'}]}
                >
                    <Input.Password placeholder="Confirm new password" />
                </Form.Item>
                <Form.Item shouldUpdate>
                    {() => (
                      <>
                          <Button
                            type="primary"
                            htmlType="submit"
                            disabled={
                                !form.isFieldsTouched(true) ||
                                form.getFieldsError().filter(({ errors }) => errors.length).length
                            }
                          >
                              Confirm Password
                          </Button>
                          &nbsp;
                          <Button style={{float: "right"}} onClick={()=>setPasswordModal(false)} >
                              Close
                          </Button>
                      </>
                    )}
                </Form.Item>
            </Form>
        </Modal>
        <Row gutter={[24, 16]}>
            <Col md={{span: 12}} lg={{span: 20}} >
                <Form {...layout} >
                    <Form.Item
                        label="Email"
                    >
                        {user.email}
                    </Form.Item>
                    {user.is_payed ? (
                        <>
                            <Form.Item label="VIP Searches Remaining" >
                                {user.satan_calls_remaining}
                            </Form.Item>
                        </>
                    ): (
                        <>
                            <Form.Item
                                label="Base Product"
                            >
                                No
                            </Form.Item>
                            <Form.Item label="Trial Expiry">
                                {user.expiry_date}
                            </Form.Item>
                            <Form.Item label="Level 2 Calls" >
                                {user.extra_level_2_calls}
                            </Form.Item>
                            <Form.Item label="VIP Searches Remaining">
                                {user.extra_satan_calls}
                            </Form.Item>
                        </>
                    )}
                    <Form.Item {...tailLayout}>
                        <Button type="primary" size="large" onClick={()=>setPasswordModal(true)} >
                            Change Password
                        </Button>
                    </Form.Item>
                </Form>
            </Col>
        </Row>
      </PlainContentWrapper>
    )
}

export default MyAccount