import React from "react";
import {Col, Row, Spin} from "antd";

const Loader = () => {
  return(
    <Row 
      type="flex" 
      align="middle"
      justify="center"
    >
      <Col>
        <Row justify="center">
          <Col className="has-text-centered" >
            <Spin size="large" style={{padding: '2rem'}} />
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default Loader