import { PostRequest} from "./Requests";
import ApiResponse from "../Models/ApiResponse"
import {GenerateUrl, RunServer} from "./ApiUrl";
import authHeader from './auth-header'

const ChangePasswordCall = async (email, password) => {
  let apiResponse;
  if (RunServer || process.env.NODE_ENV !== "development") {
    apiResponse = await PostRequest(
      GenerateUrl("/change_password"),
      {
        email, password
      },
      authHeader()
    )} else {
    apiResponse = new ApiResponse(dummyPlans(), 200, null)
  }
  if (apiResponse.body) {
    return apiResponse.body;
  } else {
    return apiResponse.error;
  }
};

function dummyPlans() {
  return {
    data: {
      "id": 1,
      "email": "admin@website.com",
    },
    success: true,
    errors: []
  }
}

export default ChangePasswordCall
