import React, {useEffect, useState} from "react";
import {Switch} from "react-router-dom";
import GetUserDetails from "../Common/ApiCall/GetUserDetails";
import {PrivateRoute} from "../Components/PrivateRoute";
import Dashboard from "../Dashboard";
import {GeneralRoute} from "../Components/GeneralRoute";
import {AuthRoute} from "../Components/AuthRoute";
import WelcomePage from "../WelcomePage";
import Auth from "../Auth";
import ForgotPassword from "./ForgotPassword";
import NotFound from "./NotFound";
import ReactGA from 'react-ga'
import Loader from "../Components/Loader";
import { getToken } from "../Common/ApiCall/Logout";
import { BadNotif } from "../Common/Utils/SendNotification";
import NotificationTypeEnum from "../Common/Models/NotificationTypeEnum";

const Home = ({...props}) => {
  const [user, setUser] = useState(null)
  const [isAuthed, setIsAuthed] = useState(false)
  const [initLoader, setInitLoader] = useState(true)

  function handleAuthed() {
    if(user) {
      setIsAuthed(true);
    }
  }

  function setUserDetails() {
    const token = getToken()
    if(token) {
      GetUserDetails(token)
        .then(r => {
          if(r.success) {
            if(r.data.user_details.is_payed) {
              setUser(r.data.user_details)
            } else {
              BadNotif(NotificationTypeEnum.Failure, 'You need to buy Profilebud to login here.')
            }
          }
          setInitLoader(false)
        })
    } else {
      setInitLoader(false)
    }
  }

  useEffect(()=> {
    setUserDetails()
    ReactGA.initialize('UA-178422717-1', {
      debug: false,
      titleCase: false
    });
  }, [])

  useEffect(()=> {
    handleAuthed()
  })

  if(initLoader) {
    return (
      <Loader />
    )
  }

  return(
    <>

      <Switch>

        <AuthRoute
          path='/sign-in'
          exact
          isAuthed={isAuthed}
          setUser={setUserDetails}
        >
          <Auth setUser={setUserDetails} signIn />
        </AuthRoute>

{/*         <AuthRoute
          path="/sign-up"
          exact
          isAuthed={isAuthed}
        >
          <Auth signUp />
        </AuthRoute> */}

        <AuthRoute
          path="/validate/:id"
          exact
        >
          <ForgotPassword />
        </AuthRoute>

        <GeneralRoute
          path="/general"
          Component={WelcomePage}
        />

        <PrivateRoute
          path="/"
          user={user}
          isAuthed={isAuthed}
          Component={Dashboard}
        />

        <GeneralRoute
          Component={NotFound}
        />

      </Switch>
    </>
  )

}

export default Home