import {PostRequest} from "./Requests";
import ApiError from "../Models/ApiError";
import {GenerateUrl} from "./ApiUrl";

const MakeCheckoutSession = async (email, price_id, currency, referrer_cookie, discount_code, is_paypal = false) => {
  const apiResponse = await PostRequest(GenerateUrl("/make_add_on_payment_session", true), {
    email,
    price_id,
    currency,
    referrer_cookie,
    is_paypal: is_paypal,
    discount_code
  });

  if (apiResponse.isValid()) {
    return apiResponse.body.data;
  } else {
    let message = `Something bad happened. Error: ${apiResponse.error.errors[0].message}. Please contact support@grambuddy.com (expect quick reply)`;
    return new ApiError(message);
  }
};

export default MakeCheckoutSession;
