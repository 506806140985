import React, { useState, useEffect } from 'react'
import { PayPalButton } from "react-paypal-button-v2";
import {Modal} from "antd";
import ContentWrapper from '../Components/ContentWrapper'
import MakeCheckoutSession from "../Common/ApiCall/MakeCheckoutSession";
import ApiError from "../Common/Models/ApiError";
import { BadNotif, SuccessNotif } from "../Common/Utils/SendNotification";
import {getToken} from '../Common/ApiCall/Logout'
import Loader from '../Components/Loader'
import SuccessfulPayment from '../Common/ApiCall/SuccessfulPayment';
import * as Sentry from "@sentry/react";

const DevId = "ASldBDuVDyw_lvwFV_xsIEeTwPHXvSn6DCwCnLyTGmN1AZUFloKh5Grtfj5jvD5Q0al02UIBS95YM30s"
const LiveId = 'Af0NdD8YDjodoxptwnUDDrSng7acgtafAAa6sfHtePagZKNJqTHV4HNnpcd6UZ5_eZQAY0YQxX0c-RzT'

const dId = 'AUBCWHcU9uHgTAzqLHnDGZytt0t0bcWe0IAs4bEZwe5VVR18wwc_dMCVnW61R4FGwA82Uu9oyQO2Pz5a'

const agencyDevId = 'Ab7N0kZdAZ8IEgfcB0301LBUJ-Z-F-IpZsOxqYHVTXBXp1V-hrbvpfUy6we_HxQ8v-t0i3pahDChORaP'
const agencyLiveId = 'AVNbu-IVRfPxFPCEbzmItQALix6XQzZ_Vl7AiMv53SuR43GvLf1F0Y3Q599tSkxaCCM34J_r1C6jjQlq'

const CLIENT = {
  sandbox: agencyDevId,
  live: agencyLiveId
}

const agencyMerchantId = 'YAD9G8QAHXQME'
const merchantId1 = 'U4AG4Y9KYQKPC'
const merchantId2 = 'GZZNKZ72H5Q2C'

const productDescription = "Profilebud Booster Plan"

function roundToTwo(num) {    
  return +(Math.round(num + "e+2")  + "e-2");
}

function getCheckoutAmount(price_in_cents, currency, paypal) {
  if(paypal) {
    price_in_cents/=100
  }
  return (roundToTwo(price_in_cents))
}

const CLIENT_ID = process.env.NODE_ENV === 'development' ? CLIENT.sandbox : CLIENT.live

const CheckoutPaypalButton = ({email, price_id, currency, back, price_in_cents, coupon_code, discount_percentage}) => {
  const [showButtons, setShowButtons] = useState(false)
  const [loading, setLoading] = useState(true)
  const [paid, setPaid] = useState(false)
  const [sessionId, setSessionId] = useState(null)

      function getReferCookie() {
        let cookie = getToken("_fprom_track")
        return cookie === undefined ? '' : cookie
      }
    
       async function init() {
        const apiSessionId = await MakeCheckoutSession(
            email,
            price_id,
            currency,
            getReferCookie(),
            coupon_code,
            true
          );
          if (apiSessionId instanceof ApiError) {
            Sentry.captureMessage(apiSessionId.message)
            BadNotif(null, sessionId.message)
            back()
          }
          setSessionId(apiSessionId)
      }

      useEffect(() => {
        init()
          .then(() => {
            setShowButtons(true)
          })
      }, [])

      const createOrder = (data, actions) => {
        return actions.order.create({
          purchase_units: [
            {
              description: productDescription,
              amount: {
                currency_code: currency,
                value: getCheckoutAmount((1 - (discount_percentage/100))*(price_in_cents), currency, true)
              },
              payee: {
                merchantId: agencyMerchantId
              }
            }
          ]
        });
      };
    
      const onApprove = (data, actions) => {
        actions.order.capture().then(details => {
          const paymentData = {
            payerID: data.payerID,
            orderID: data.orderID
          };
          console.log("Payment Approved: ", paymentData);
          setShowButtons(false);setPaid(true)
          SuccessfulPayment(sessionId)
            .then(r => {
              if(r.success) {
                SuccessNotif("Payment Completed")
              } else {
                Sentry.captureException(r)
                BadNotif('Something Went Wrong. Kindly contact support@profilebud.com')
              }
            })
        });
      };

      return(
          <ContentWrapper>
              <Modal
                width={700}
                visible={true}
                onCancel={back}
                footer={null}
              >
              {loading ? <Loader /> : null}
  
                  {showButtons ? (
                  <div>
                  <div>
                  <h1>Item: {productDescription}</h1>
                  <h2>Total Checkout Amount: $ {getCheckoutAmount(((1 - (discount_percentage/100))*price_in_cents), currency, true)}</h2>
                  <br />
                  </div>

                  <PayPalButton
                    createOrder={(data, actions) => 
                      createOrder(data, actions)
                      }
                    onApprove={
                      (data, actions) => 
                      onApprove(data, actions)
                      }
                    onButtonReady={()=>{
                      setLoading(false)
                    }}
                    options={{
                      clientId: CLIENT_ID
                    }}
                  />
              </div>
                  ) : null
                  }
  
                  {paid && (
                  <div className="main">
                      <h2>
                      Congrats! your payment was successful. Redirecting you to dashboard{" "}
                      <span role="img" aria-label="emoji">
                          {" "}
                          😉
                      </span>
                      </h2>
                  </div>
                  )}
              </Modal>
        </ContentWrapper>
      )
}

export default CheckoutPaypalButton