import React from "react";
import {Link} from "react-router-dom";
import {Button, Card} from "antd";

const PaymentFailure = () => {
  return (
    <>
      <Card
        style={{
          margin: '20px',
          borderRadius: '10px',
        }}
      >
        <div className="vertically-center">
          <h1 style={{fontSize: 25}} className="has-text-centered" >
            Oops, Something went wrong <span aria-label={"not found emoji"} role={"img"}>😔</span>.
            Please try again.
            <br/>
            <Button className="ant-btn-primary" size={'large'}>
              <Link to={"/boosters"}>
                Try Again
              </Link>
            </Button>
          </h1>
        </div>
      </Card>
    </>
  );
}

export default PaymentFailure;
