import React from "react";
import {Layout} from "antd";

const {Content} = Layout

const PlainContentWrapper = ({children, marginLess, classAppend}) => {
  
  return(
    <Content
      className={`content-wrapper theme-background ${marginLess ? 'content-wrapper-margin-less' : ''} ${classAppend}`}
    >
      {children}
    </Content>
  )
}

export default PlainContentWrapper