import React from 'react';
import {Button, Card} from "antd";
import {Link} from "react-router-dom";

const PaymentSuccess = (props) => {

  return (
    <>
      <Card
        style={{
          margin: '20px',
          borderRadius: '10px',
        }}
      >
        <div className="vertically-center has-text-centered">
          <h1 style={{fontSize:40}}>THANK YOU</h1>
          <h1 style={{fontSize: 30}}>Your Payment was Successful <span role={"img"} aria-label={"success emoji"}> 🎉</span> </h1>
        </div>
      </Card>
    </>
  );
}

export default PaymentSuccess;
