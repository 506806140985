import React, {useState, useEffect} from 'react'
import PlainContentWrapper from '../Components/PlainContentWrapper'
import {Typography, Card, Divider, Row, Col, Button} from 'antd'
import IFrameComponent from '../Components/IFrameComponent'
import GetTrainingItems from '../Common/ApiCall/GetTrainingItems'
import Loader from "../Components/Loader";

const {Title} = Typography

const Tutorial = ({setKey}) => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setKey()
    GetTrainingItems()
      .then(r => {
        setData(r)
        setLoading(false)
      })
  }, [])

    return(
        <PlainContentWrapper marginLess classAppend="is-padded-top" >
        <Title className="has-text-centered" >
            Training
        </Title>
        <Divider />
        {loading ? <Loader />: null}
        <Row gutter={[24, 24]} align="center" >
        {data.map((item)=>(
          <Col key={item.id} sm={{span: 24}} md={{span: 20}} lg={{span: 16}} className="is-marginless">
            <Card>
            <Title level={3} style={{margin: '0 1rem'}}>
              {item.title}
            </Title>
            <Divider />
            {item.is_blog ? (
                <Typography.Text style={{margin: '1.3rem'}} >
                  {item.excerpt}
                  <br />
                    <Button link href={item.link} target="_blank" >
                        Continue Reading ...
                    </Button>
                </Typography.Text>        
            ): (
                <IFrameComponent url={item.link} />
            )}
            </Card>
          </Col>
        ))}
      </Row>
        </PlainContentWrapper>
    )
}

export default Tutorial