import React, {useEffect, useState} from "react";
import {Layout, Menu, Button, Affix} from "antd";
import logo from '../Common/Images/logo-full.png'
import logoOnly from '../Common/Images/logo.png'
import {
  QuestionCircleOutlined,
  UserOutlined,
  HomeOutlined,
  PlusCircleOutlined,
  VideoCameraOutlined,
  RotateRightOutlined
} from '@ant-design/icons';
import {Switch} from "react-router";
import HomePage from "./HomePage";
import isMobile from "is-mobile";
import FAQ from "./FAQ";
import {Route} from "react-router";
import {Link} from "react-router-dom";
import Boosters from "./Boosters";
import MyAccount from "./MyAccount";
import logout from "../Common/ApiCall/Logout";
import Tutorial from './Tutorial'
import WhiteLabel from "./WhiteLabel";

const {Header, Sider} = Layout

const MenuItems = [
  {
    key: 1,
    icon: <HomeOutlined />,
    title: 'Home',
    link: '/'
  },
  {
    key: 2,
    title: 'Booster',
    link: '/boosters',
    icon: <PlusCircleOutlined />
  },
  {
    key: 6,
    title: 'Whitelabel',
    link: '/whitelabel',
    icon: <RotateRightOutlined />,
    hide: true
  },
  {
    key: 3,
    title: 'My Account',
    link: '/my-account',
    icon: <UserOutlined />
  },
  {
    key: 4,
    title: 'Faq',
    icon: <QuestionCircleOutlined />,
    link: '/faq',
    hide: true
  },
  {
    key: 5,
    title: 'Training',
    icon: <VideoCameraOutlined />,
    link: '/training'
  }
]

const Dashboard = ({user, ...props}) => {
  const [collapsed, toggleCollapsed] = useState(false)
  const [key, setKey] = useState(1)

  function toggle() {
    toggleCollapsed(!collapsed);
  }

  function toggleOnMobile() {
    if(isMobile()) {
      //toggleCollapsed(true);
    }
  }

  //useEffect(()=>toggleOnMobile(), [])

  return(
    <Layout className="is-fullheight">

      <Layout className="site-layout">

      <Sider 
          collapsible 
          collapsed={collapsed} 
          onCollapse={toggle}
        >

          <Menu selectedKeys={String(key)} mode="inline" onClick={(key)=>setKey(parseInt(key.key))} defaultSelectedKeys={['1']}>
            <Menu.Item 
              style={{marginTop: '20px', marginBottom: '20px'}}
              className="is-bold"
            >
              <img
                style={{maxHeight: '40px'}}
                alt="Logo"
                src={logoOnly}
              />
              &nbsp;&nbsp;PROFILEBUD
            </Menu.Item>

            {MenuItems.map((item)=>(
              item.hide ? null : (
                <Menu.Item key={item.key} icon={item.icon}>
                <Link to={item.link}>
                  {item.title}
                </Link>
              </Menu.Item>
              )
            ))}
          </Menu>

        </Sider>

        <Affix style={{ position: 'absolute', top: 40, right: 80 }}>
          <Button onClick={()=>logout()} type="primary" className="dark-button">
            Log out
          </Button>
        </Affix>

        <Switch>
          <Route
            path="/faq"
            exact
            component={() => (
              <FAQ setKey={()=>setKey(4)} />
            )}
          />

          <Route 
            path="/boosters"
              component={(props)=> (
                <Boosters {...props} user={user} setKey={()=>setKey(2)} />
              )}
          />

          <Route 
            path="/my-account"
            component={(props)=>(
              <MyAccount setKey={()=>setKey(3)} user={user} />
            )}
          />

          <Route 
            path="/training"
            component={(props)=>(
              <Tutorial setKey={()=>setKey(5)} />
            )}
          />

          <Route 
            path="/whitelabel"
            component={()=>(
              <WhiteLabel user={user} setKey={()=>setKey(6)} />
            )}
          />

          <Route
            component={(props) => (
              <HomePage {...props} setKey={()=>setKey(1)} user={user} />
            )}
          />

        </Switch>

      </Layout>

    </Layout>
  )
}

export default Dashboard