import ApiError from "../Models/ApiError";

export let ApiUrl = "https://api2.profilebud.com/insta/api/v5"

let ApiUrlV6 = "https://api2.profilebud.com/insta/api/v6"
const dv6 = "http://localhost:3000/insta/api/v6"
const dv5 = "http://localhost:3000/insta/api/v5"

export const DevUrl = "https://localhost:3000"

export const RunServer = true

export function GenerateUrl(path, newPath = false) {
  if(newPath) {
    return ApiUrlV6 + path
  }
  return(ApiUrl+path)
}

let message = "Something went wrong. Error: "

export function ConstructErrorMessage(ApiMessage) {
  return new ApiError(message+ApiMessage)
}