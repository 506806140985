import {PostRequest} from "./Requests";
import ApiResponse from "../Models/ApiResponse";
import ApiError from "../Models/ApiError"
import {GenerateUrl, RunServer} from "./ApiUrl";

const ValidateCoupon = async (coupon_code) => {
  let apiResponse;
  if ( RunServer || process.env.NODE_ENV !== "development") {
    apiResponse = await PostRequest(GenerateUrl("/validate_coupon" , true), {
      coupon_code
    });
  } else {
    apiResponse = new ApiResponse(dummyPlans(), 200, null)
  }
  if (apiResponse.body) {
    return apiResponse.body;
  } else {
    return apiResponse.error
  }
};

function dummyPlans() {
  return {
    "data": {
      "valid": true,
      "percent_off": 25
    },
    "success": true,
    "error": null
  }
}

export default ValidateCoupon