import React, {useState, useEffect} from "react";
import {Row, Col, Card, Typography, message, Divider, Button, Form, Modal} from 'antd'
import ContentWrapper from "../Components/ContentWrapper";
import GetPlansStripe from '../Common/ApiCall/GetPlansStripe'
import CheckoutPaymentScreen from "./CheckoutPaymentScreen";
import image from '../Components/assets/stars_baseline.png'
import PlainContentWrapper from "../Components/PlainContentWrapper";
import Loader from "../Components/Loader";
import img1 from '../Common/Images/plan_1.png'
import img2 from '../Common/Images/plan_2.png'
import img3 from '../Common/Images/plan_3.png'
import ReactFlagsSelect from "react-flags-select";
import BoosterModal from './BoosterModal'
import * as Sentry from "@sentry/react";

const {Meta} = Card
const {Title} = Typography

const planFeatures = [
  {
    text: "3x times more fast and more User Analysis daily searches"
  },
  {
    text: "Lifetime Valid | Pay as you Use | NOT a subscription"
  }
]

function getColor(name) {
  name = name.toUpperCase()
  switch(name) {
    case "BOOSTER PLAN 1": return "#E6E6E6"
    case "BOOSTER PLAN 2": return "#FFDC67"
    case "BOOSTER PLAN 3": return "#56EAEF"
    default: return "#E6E6E6"
  }
}

function getImage(name) {
  name = name.toUpperCase()
  switch (name) {
    case "BOOSTER PLAN 1": return img1
    case "BOOSTER PLAN 2": return img2
    case "BOOSTER PLAN 3": return img3
    default: return img2
  }
}

const Boosters = ({user, setKey, ...props}) => {
  const [booster_plans, set_booster_plans] = useState([])
  const [checkout_price_id, setCheckout] = useState(null)
  const [loading,setLoading] = useState(true)
  const [visible, setVisible] = useState(false)
  const [price_in_cents, set_price_in_cents] = useState(null)
  const [cc, setCc] = useState('usd')

  function getBoosterPlans() {
    GetPlansStripe()
    .then(r => {
      set_booster_plans(r)
      setLoading(false)
      if(r.success === false) {
        Sentry.captureException(r)
      } 
    })
  }

  useEffect(()=>{
    setKey()
    getBoosterPlans()
  }, [])

  const createPayment = (cc) => {
    setVisible(false)
    let currency = 'usd';
    if(cc === 'IN') {
      currency = 'inr'
    }
    setCc(currency)
  }

  function back() {
    setVisible(false)
    setCheckout(null)
  }

  if(visible) {
    return(
      <BoosterModal 
        visible={visible}
        back={back}
        email={user.email}
        price_in_cents={price_in_cents}
        pId={checkout_price_id}
      />
    )
  }

  if(checkout_price_id) {
    return(
      <CheckoutPaymentScreen 
        email={user.email}
        price_id={checkout_price_id}
        price_in_cents={price_in_cents}
        currency={cc}
        goBack={()=>setCheckout(null)}
      />
    )
  }

  if(loading) {
    return(
      <PlainContentWrapper 
        marginLess
        classAppend="is-padded-top"
      >
        <Title className="is-uppercase">
          Booster Plans
        </Title>
        <Divider />
        <Loader />
      </PlainContentWrapper>
    )
  }

/*   return(
    <PlainContentWrapper 
      marginLess={true}
      classAppend="is-padded-top"
    >
      <Title className="is-uppercase" level={3}>
        Not Available
      </Title>
    </PlainContentWrapper>
  ) */

  return(
    <PlainContentWrapper 
      marginLess={true}
      classAppend="is-padded-top"
    >
      <Title className="is-uppercase">
        Booster Plans
      </Title>
      <br />
      <Row gutter={[24, 16]} justify="space-around" >
        {booster_plans.map((item)=>(
          <Col md={{span: 8}} xl={{span: 6}} key={item.id}>
            <Card
              cover={
                <img
                  alt="example"
                  src={getImage(item.name)}
                />
              }
            >
              <div className="align-center">
                <Title className="is-uppercase" level={4} >
                {item.satan_calls/1000}K Fast profile searches (uses our proxy network)
                </Title>
                {planFeatures.map((item, index) => (
                <Title className="is-uppercase" level={4} key={index} >
                  {item.text}
                </Title>
                ))}
                <br />
                <Button 
                  onClick={()=>{set_price_in_cents(item.price_in_cents);setCheckout(item.id);setVisible(true);}} 
                  block 
                  size="large" 
                  shape="round"
                  type="primary dark-button"
                >
                  Buy Now
                </Button>
              </div>
            </Card>
          </Col>
        ))}
      </Row>
    </PlainContentWrapper>
  )
}

export default Boosters