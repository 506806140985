import Cookies from "universal-cookie"

const DEV_DOMAIN = 'localhost'

const COOKIE_PATH = "/"
const COOKIE_DOMAIN = process.env.NODE_ENV === "development" ? DEV_DOMAIN : "profilebud.com"
const COOKIE_NAME = "authToken"

const cookies = new Cookies()

export function getToken() {
  return cookies.get(COOKIE_NAME)
}

export function setToken(token) {
  cookies.set(COOKIE_NAME, token, {path: COOKIE_PATH,domain: COOKIE_DOMAIN})
}

export default function logout() {
  cookies.remove(COOKIE_NAME, {path: COOKIE_PATH,domain: COOKIE_DOMAIN})
  window.location.reload()
}