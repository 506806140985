import {GetRequest} from "./Requests";
import ApiResponse from "../Models/ApiResponse";
import ApiError from "../Models/ApiError"
import {RunServer, GenerateUrl} from "./ApiUrl";

const GetPlansStripe = async () => {
  let apiResponse;
  if (RunServer || process.env.NODE_ENV !== "development") {
    apiResponse = await GetRequest(GenerateUrl("/payment/add_ons"));
  } else {
    apiResponse = new ApiResponse(basePlan(), 200, null)
  }
  if (apiResponse.isValid()) {
    return apiResponse.body.data;
  } else {
    let message = `Something went wrong. Please contact support@profilebud.com. Error: ${apiResponse.error}`;
    return new ApiError(message);
  }
};

function basePlan(newParam = {
  "data": {
    "id": 1,
    "stripe_price_id": "price_1HS5oh",
    "name": "Base",
    "price_in_cents": 1400,
    "satan_calls": 1000,
    "is_add_on": false,
    "created_at": "2020-09-21T00:06:19.048Z",
    "updated_at": "2020-09-21T00:06:19.048Z"
  }, "success": true, "errors": []
}) {
  return newParam
}

function dummyPlans(newParam = {
  "data": {
    "subscription": [{
      "id": 1,
      "stripe_price_id": "price_1HS5nyHxZlQNSFkkGIHI24OS",
      "name": "Basic",
      "price_in_cents": 2900.0,
      "is_monthly": true,
      "is_fast": false,
      "created_at": "2020-09-21T00:06:19.025Z",
      "updated_at": "2020-09-21T00:06:19.025Z"
    }, {
      "id": 2,
      "stripe_price_id": "price_1HS5ohHxZlQNSFkkUxwVnFR2",
      "name": "Basic",
      "price_in_cents": 14900.0,
      "is_monthly": false,
      "is_fast": false,
      "created_at": "2020-09-21T00:06:19.048Z",
      "updated_at": "2020-09-21T00:06:19.048Z"
    }, {
      "id": 3,
      "stripe_price_id": "price_1HS5pJHxZlQNSFkkzgRjnRW9",
      "name": "SuperCharged",
      "price_in_cents": 19900.0,
      "is_monthly": true,
      "is_fast": true,
      "created_at": "2020-09-21T00:06:19.051Z",
      "updated_at": "2020-09-21T00:06:19.051Z"
    }, {
      "id": 4,
      "stripe_price_id": "price_1HS5qIHxZlQNSFkkp3TQzVb6",
      "name": "SuperCharged",
      "price_in_cents": 149900.0,
      "is_monthly": false,
      "is_fast": true,
      "created_at": "2020-09-21T00:06:19.053Z",
      "updated_at": "2020-09-21T00:06:19.053Z"
    }],
    "one_time": [{
      "id": 1,
      "name": "Bronze",
      "price_in_cents": 1500,
      "level_2_calls": 300000,
      "level_3_calls": 30000,
      "created_at": "2020-09-21T00:06:25.378Z",
      "updated_at": "2020-09-21T00:06:25.378Z"
    }, {
      "id": 2,
      "name": "Silver",
      "price_in_cents": 4900,
      "level_2_calls": 1000000,
      "level_3_calls": 120000,
      "created_at": "2020-09-21T00:06:25.385Z",
      "updated_at": "2020-09-21T00:06:25.385Z"
    }, {
      "id": 3,
      "name": "Gold",
      "price_in_cents": 9900,
      "level_2_calls": 1000000,
      "level_3_calls": 300000,
      "created_at": "2020-09-21T00:06:25.387Z",
      "updated_at": "2020-09-21T00:06:25.387Z"
    }]
  }, "success": true, "error": null
}) {
  return newParam
}

export default GetPlansStripe;
