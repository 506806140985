import {GetRequest} from "./Requests";
import ApiResponse from "../Models/ApiResponse";
import ApiError from "../Models/ApiError"
import {RunServer, GenerateUrl} from "./ApiUrl";
import authHeader from './auth-header'

const GetTrainingItems = async () => {
  let apiResponse;
  if ( RunServer || process.env.NODE_ENV !== "development") {
    apiResponse = await GetRequest(GenerateUrl("/training_items"), {}, authHeader());
  } else {
    apiResponse = new ApiResponse(basePlan(), 200, null)
  }

  if (apiResponse.isValid()) {
    return apiResponse.body.data;
  } else {
    let message = `Something went wrong. Please contact support@profilebud.com. Error: ${apiResponse.error}`;
    return new ApiError(message);
  }
};

function basePlan(newParam = {
  "data": {
    "id": 1,
    "stripe_price_id": "price_1HS5oh",
    "name": "Base",
    "price_in_cents": 1400,
    "satan_calls": 1000,
    "is_add_on": false,
    "created_at": "2020-09-21T00:06:19.048Z",
    "updated_at": "2020-09-21T00:06:19.048Z"
  }, "success": true, "errors": []
}) {
  return newParam
}

export default GetTrainingItems;
