import React, {useEffect, useState} from 'react'
import {Row, Col, Card, Layout, Typography, Divider, Input, Button, Spin} from 'antd'
import {ArrowRightOutlined} from '@ant-design/icons'
import ContentWrapper from "../Components/ContentWrapper";
import PlainContentWrapper from "../Components/PlainContentWrapper";
import GetIgAccounts from '../Common/ApiCall/GetIgAcccounts';
import AddIgAccount from '../Common/ApiCall/AddIgAccount'
import ApiError from '../Common/Models/ApiError'
import GetIgDataFromUsername from '../Common/ApiCall/GetIgDataFromUsername'
import SendNotification from '../Common/Utils/SendNotification'
import {BadNotif} from '../Common/Utils/SendNotification'
import NotificationTypeEnum from '../Common/Models/NotificationTypeEnum'
import IgAccountCard from './IgAccountCard';
import queryString from "query-string";
import GetCheckoutSessionDetails from '../Common/ApiCall/GetCheckoutSessionDetails'
import PaymentSuccess from "./PaymentSuccess";
import PaymentFailure from "./PaymentFailure";
import Loader from '../Components/Loader';
import * as Sentry from "@sentry/react";

const {Title} = Typography

const GetStartedCard = () => {
  return(
    <Card
      className="has-text-centered starter-card"
    >
      See how to give access and get started 
      <a 
        className="link-text"
        href="https://profilebud.com/blogs/how-to-give-access-to-instagram-account/"
        target="_blank"
        rel="noopener noreferrer"
      >
        &nbsp;<u>here</u>&nbsp;
      </a> 
    </Card>
  )
}

const HomePage = ({user, setKey, ...props}) => {
  const [username, setUsername] = useState('')
  const [detailed_accounts, set_detailed_accounts] = useState([])
  const [loading, setLoading] = useState(true)
  const [igAccounts, setIgAccounts] = useState([])
  const [session_is_redirected, set_redirected] = useState(false)
  const [session_success, set_session_status] = useState(false)

  function getIgAccounts() {
    setLoading(true)
    GetIgAccounts()
    .then(r => {
      setIgAccounts(r.data)
      setLoading(false)
      // makeDetailedIgObjects(r.data)
    })
  }

  function checkCheckoutId() {
    if(props.location.search) {
      let params = queryString.parse(props.location.search)
      if(params.session_id) {
        GetCheckoutSessionDetails(params.session_id)
        .then(r => {
          set_redirected(true)
          if(r.success) {
            if(r.data.has_payed) {
              set_session_status(true)
            }
          } else {
            Sentry.captureException(r)
          }
        })
      }
    }
  }

  useEffect(()=>{
    setKey()
    checkCheckoutId()
    getIgAccounts()
  }, [])

  async function makeDetailedIDFromUsernames() {
    setLoading(true)
    if(username === '') {
      SendNotification(NotificationTypeEnum.Failure, "Enter Username to Continue")
      setLoading(false)
      return
    }
    let userData = await GetIgDataFromUsername(username)
    if(userData instanceof ApiError) {
      if(userData.message === "User does not exist") {
        SendNotification(NotificationTypeEnum.Failure, "Instagram username does not exist");
      }
      setLoading(false)
      return
    }
    if(!userData.id) {
      SendNotification(NotificationTypeEnum.Failure, "Instagram username does not exist");
      setLoading(false)
      return
    }
    addIgAccount(userData.id, userData.username)
    setLoading(false)
  };

  async function getMultipleAtOnce(usernames) {
    if(usernames.length === 0) {
      return []
    }
    else if (usernames.length === 1) {
      return await Promise.all([GetIgDataFromUsername(usernames[0], false)]);
    } else if(usernames.length === 2) {
      return await Promise.all([
        GetIgDataFromUsername(usernames[0]),
        GetIgDataFromUsername(usernames[1])
      ])
    }
    let [a, b, c] = await Promise.all([
      GetIgDataFromUsername(usernames[0]),
      GetIgDataFromUsername(usernames[1]),
      GetIgDataFromUsername(usernames[2])
    ]);
    return [a, b, c];
  }

  async function makeDetailedIgObjects(accounts) {
    let usernames = []
    for(let i=0;i<accounts.length;i++) {
      usernames.push(accounts[i].username)
    }
    let detailed_ig_accounts = await getMultipleAtOnce(usernames)
    set_detailed_accounts(detailed_ig_accounts)
    setLoading(false)
  }

  async function addIgAccount(ig_id, username) {
    AddIgAccount(ig_id, username)
    .then(r => {
      getIgAccounts()
      if(r.success) {
        SendNotification(NotificationTypeEnum.Success, "Ig Account Added")
      } else {
        BadNotif(r)
      }
    })
  }

  function changeUsername(e) {
    let value = e.target.value
    if(value && value[0]==='@') {
      setUsername(value.slice(1))
    } else {
      setUsername(value)
    }
  }

  return(
    <PlainContentWrapper 
      marginLess
      classAppend="is-fullheight-with-navbar is-padded-top"
    >
      <Row 
        type="flex" 
        align="middle"
        gutter={24} 
        justify="center" 
        className="height-full"
        style={{paddingBottom: '200px'}}
      >
        <Col md={{span: 20}} >
        <Title className="has-text-centered no-letter-spacing is-uppercase is-bold" >
          Profilebud Access Dashboard
        </Title>
        <GetStartedCard />
        {
        session_is_redirected ? (
          session_success ? (
            <PaymentSuccess />
          ) : (
            <PaymentFailure />
          )
        ) : <br />
      }
            <Input
              size="large"
              style={{
                borderRadius: '51px', 
                marginTop: '3%', 
                padding: '1.5% 2% 1.5% 3%',
                marginBottom: '3rem'
              }}
              placeholder="Enter Username"
              onChange={e=>changeUsername(e)}
              allowClear
              suffix={
                <ArrowRightOutlined onClick={makeDetailedIDFromUsernames} />
              }
              onPressEnter={makeDetailedIDFromUsernames}
            />
            {
              loading ? <Loader size="large" /> : null
            }
            <IgUsers 
              refresh={getIgAccounts}
              igAccounts={igAccounts}
              accounts={igAccounts} 
            />
        </Col>
      </Row>
    </PlainContentWrapper>
  )
}

const IgUsers = ({accounts = [], igAccounts, refresh}) => {

  let accounts_show = accounts.map((account, index)=>(
    <IgAccountCard 
      refresh={refresh}
      key={index}
      ig_user={account}
      igAccounts={igAccounts}
      ig_id={account.ig_id}
      ig_username={account.username}
      ig_profile_url={account.avatar}
  />
  ))

  return(
    <Row justify="space-around" style={{paddingTop: '4%'}}>
      {accounts_show}
    </Row>
  )
}

export default HomePage